@import 'variables';
@import 'fonts';
@import 'zindex';


//general
body,
.main,
Page {
  background-color: white;
  color: $darkblue;
  margin: 0;
  font-family: 'accuraregular';
  font-size: 16px;
  line-height: 1.6;

  .max-width {
    max-width: 100vh;
    margin-left: auto;
    margin-right: auto;
  }

  .form-error-heading {
    color: $red;
  }


  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .button-link {

    display: block;
    padding: 15px 30px;
    border-radius: 30px;
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    cursor: pointer;
    font-style: italic;

    &.red {
      background-color: $red;
    }
    &.blue {
      background-color: $lightblue;
    }
  }

  .block {
    h2 {
      font-size: 24px;
      font-style: italic;
      margin-top: 0;
    }
  }
}


//moble
.main {

  font-size: 16dpi;
  .button-link {
    padding: 15dpi 30dpi;
    border-radius: 30dpi;
  }
}