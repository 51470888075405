@font-face {
  font-family: 'accuraregular';
  src:
      url('../../../../shared/assets/src/lib/fonts/accura-regular.woff') format('woff'),
}

@font-face {
  font-family: 'accuraitalic';
  src:
      url('../../../../shared/assets/src/lib/fonts/accura-regular-italic.woff') format('woff'),
}

.large-title {
  font-weight: bold;
  font-size : 32px;
  text-transform: uppercase;
  margin-top: 0;
}

.italic {
  font-family: 'accuraitalic';
}
